import '@popperjs/core';
import * as bootstrap from 'bootstrap';
import "jquery";
import 'htmx.org';
import Alpine from 'alpinejs';

//3rd party libs specific js

//Init HTMX
window.htmx = require('htmx.org');
window.$ = jQuery
window.jQuery = jQuery

//htmx.logAll();

// Init Alpine JS
window.Alpine = Alpine;
Alpine.start();

//Init Bootstrap JS
window.bootstrap = bootstrap;
